import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { OAUTH_DATA, REDIRECT_TARGET } from './oauth.data';
import { OauthPayload } from '@app/activities/document.model';

@Component({
  selector: 'sso',
  templateUrl: './sso.component.html',
  styleUrls: ['./sso.component.scss'],
})
export class SsoComponent implements OnInit, AfterViewInit {
  oauthParams: { launch_url?: string } = {};

  @ViewChild('ltiForm', { static: false }) ltiForm: HTMLFormElement;

  constructor(
    @Inject(OAUTH_DATA) public oauthPayload: OauthPayload,
    @Inject(REDIRECT_TARGET) public redirectTarget: string,
  ) {}

  ngOnInit() {
    Object.keys(this.oauthPayload).forEach((camelCaseKey: string) => {
      const kebabCaseKey = camelCaseKey.replace(/([A-Z])/g, (char) => '_' + char.toLowerCase());
      this.oauthParams[kebabCaseKey] = this.oauthPayload[camelCaseKey];
    });
  }

  ngAfterViewInit() {
    this.ltiForm.nativeElement.submit();
  }
}
