<div class="sso">
  <form
    #ltiForm
    *ngIf="oauthParams"
    [action]="oauthParams.launch_url"
    [target]="redirectTarget"
    method="POST">
    <div>
      <input
        *ngFor="let key of oauthParams | keys"
        [name]="key"
        [value]="oauthParams[key]"
        type="hidden" />
    </div>
  </form>
</div>
